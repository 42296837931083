import { Form, Formik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Toast,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import * as yup from "yup";
import { getBankCurrency, getCountries } from "../../services/CommonService";
import { toast } from "react-toastify";
import {
  deleteGlobalAccounts,
  getGlobalAccounts,
  saveGlobalAccount,
  updateGlobalAccount,
  downloadGlobalAccountStatement
} from "../../services/GlobalAccountsService";
import { useSelector } from "react-redux";
import GlobalAccountModel from "./globalAccountModel";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import jsonCountryCurrency from '../../data/COUNTRY_CURRENCY.json';

const GlobalAccounts = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.data?.userDTO);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [globalAccounts, setGlobalAccounts] = useState([]);
  const [openModel, setModelOpen] = useState(false);
  const [selectedGlobalAccount, setSelectedGlobalAccount] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState({})
  const [openCreateGlobalAccountModal, setCreateGlobalAccountModal] = useState(false);
  const [isFormGlobalAccountsValues, setFormGlobalAccountsValues] = useState([]);
  const GLOBAL_ACCOUNT_FEE = process.env.REACT_APP_GLOBAL_ACCOUNT_FEE
  const ADDITIONAL_GLOBAL_ACCOUNT_FEE = process.env.REACT_APP_ADDITIONAL_GLOBAL_ACCOUNT_FEE

  const [paymentsMethods, setPaymentMethods] = useState([
    {
      label: "LOCAL",
      value: "LOCAL",
    },
    {
      label: "SWIFT",
      value: "SWIFT",
    },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCountries();
    // fetchBankCurrency();
    fetchGlobalAccounts();
  }, []);

  const deleteToggleModal = () => {
    setOpenDeleteModal(false)
    setOpenWarningModal(false)
  };
  const [columns, setColumns] = useState([
    { title: t("ACCOUNT_NAME"), field: "accountName", width: 200 },
    { title: t("ACCOUNT_NUMBER"), field: "accountNumber", width: 200 },
    { title: t("NICK_NAME"), field: "nickName", width: 200 },
    { title: t("COUNTRY_CODE"), field: "countryCode", width: 200 },
    { title: t("CURRENCY"), field: "currency", width: 200 },
    // { title: t("PAYMENT_METHOD"), field: 'paymentMethod' },
    // { title: t("STATUS"), field: "status" },
    {
      title: t("STATUS"),
      field: "status",
      width: 200,
      render: (rowData) => {
        const badgeColor =
          rowData.status === "ACTIVE"
            ? "bg-primary"
            : "INACTIVE"
            ? "bg-warning"
            : "CLOSED"
            ? "bg-danger"
            : "";
        const badgeText =
          rowData.status === "ACTIVE"
            ? "Active"
            : "INACTIVE"
            ? "Inactive"
            : "CLOSED"
            ? "Close"
            : "";

        return <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>;
      },
    },
  ]);

  const tableRowDeleteHandler = async (rowData) => {
    setRowDataToDelete(rowData)
    if(rowData.status == "ACTIVE"){
      setOpenDeleteModal(true);
    }else{
      setOpenWarningModal(true)
    }
    
  };

  const confirmDelete = async () => {
    setRowDataToDelete({})
    setOpenDeleteModal(false);
    try {
      const response = await deleteGlobalAccounts(
        rowDataToDelete.airwallexGlobalAccountId
      );
      fetchGlobalAccounts();
      toast.success(t("SUCCESSFULLY_GLOBAL_ACCOUNT_DELETE"));
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Error: ${error.response.data.result.error}`);
      }

      
      toast.error(t("ERROR_DELETE_GLOBAL_ACCOUNTS"))
    }
};

  const tableRowViewHandler = (rowData) => {
    setSelectedGlobalAccount(rowData);
    setModelOpen(true);
  };

  const fetchGlobalAccounts = async () => {
    setLoading(true);

    try {
      const response = await getGlobalAccounts(user.coUserId);

      // const modifiedData = response.result.map(item => ({
      //   ...item,
      //   //paymentMethod:item.paymentMethod.length === 1 ? item.paymentMethod[0] : (<p>{item.paymentMethod[0]} | {item.paymentMethod[1]}</p>)
      //   paymentMethod:item.paymentMethod.length > 0 ? item.paymentMethod.join(" | ") : ""
      // }));
      setGlobalAccounts(response.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //toast.error(t("ERROR_FETCH_GLOBAL_ACCOUNTS"))
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      let countries = response.result.map((country) => ({
        label: country.country_name,
        value: country.country_code,
      }));

      setCountries(countries);
    } catch (error) {
      toast.error(t("ERROR_FETCH_COUNTRIES"));
    }
  };

  // const fetchBankCurrency = async () => {
  //   try {
  //     const response = await getBankCurrency();

  //     let bankCurrency = response.result.map((bankCurrency) => ({
  //       label: bankCurrency.bank_currency,
  //       value: bankCurrency.bank_currency_code,
  //     }));

  //     setCurrencies(bankCurrency);
  //   } catch (error) {
  //     toast.error(t("ERROR_FETCH_BANK_CURRENCY"));
  //   }
  // };


  const createGlobalAccountToggleModal = () => setCreateGlobalAccountModal(!openCreateGlobalAccountModal);

  const confirmCreateGlobalAccount =  async () => {
    setCreateGlobalAccountModal(false)
    setLoading(true);

    try {
      const data = {
        country_code: isFormGlobalAccountsValues.country_code,
        currency: isFormGlobalAccountsValues.currency,
        nick_name: isFormGlobalAccountsValues.nick_name,
        payment_methods: [isFormGlobalAccountsValues.payment_methods],
        request_id: null,
      };
      const response = await saveGlobalAccount(data, user.coUserId);
      fetchGlobalAccounts();
      setLoading(false);
      toast.success(t("SUCCESSFULLY_GLOBAL_ACCOUNT_CREATE"));
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Error: ${error.response.data.result.error}`);
      }
      //toast.error(t("ERROR_GLOBAL_ACCOUNT_CREATE"))
    }
  }

  const updateGlobalAccountData = async (newNickName, globalAccountId) => {

    setLoading(true);
    try {
      const data = {
        globalAccountId: globalAccountId,
        nickName: newNickName,
      };
      const response = await updateGlobalAccount(data, user.coUserId);
      fetchGlobalAccounts();
      setLoading(false);
      toast.success(t("SUCCESSFULLY_GLOBAL_ACCOUNT_CREATE"));
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Error: ${error.message}`);
        // toast.error(`Error: ${error.response.data.result.error}`);
      }
      //toast.error(t("ERROR_GLOBAL_ACCOUNT_CREATE"))
    }

  }


  const validationSchema = yup.object().shape({
    nick_name: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
    country_code: yup.string().required(t("COUNTRY_IS_REQUIRED")),
    currency: yup.string().required(t("CURRENCY_IS_REQUIRED")),
    payment_methods: yup.string().required(t("PAYMENT_METHOD_IS_REQUIRED")),
  });

  const onSubmit = (values, { resetForm }) => {
    createGlobalAccountToggleModal()
    setFormGlobalAccountsValues(values)
    resetForm()
  };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  // const handleContinueClick = () => {
  //   if(isChecked == true){
  //     setAdvisoryMsgConfirm(true)
  //     setModal(false)
  //   }
  //   // submitForm()
  // };

  const changeCountryHandler = (value, form) => {
    form.setFieldValue("country_code", value)
    setCurrencies([]);
    const data = jsonCountryCurrency.find((country) => country.COUNTRY_CODE == value)

      let bankCurrency = data?.CURRENCY.map((bankCurrency) => ({
        label: bankCurrency,
        value: bankCurrency,
      }));
  
      setCurrencies(bankCurrency);
  
  }

  const downloadStatement = async (rowData) => {
    try {
      const data = {
        coUserId: user.coUserId,
        airwallexGlobalAccountId: rowData.airwallexGlobalAccountId,
      }
      const response = await downloadGlobalAccountStatement(data);
      const base64Data = response.result;
      console.log("base64Data", base64Data)

      //Convert Base64 string to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob and open it in a new tab
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    } catch (error) {
      toast.error(t("ERROR_DOWNLOAD_STATEMENT"));
    }
  }

  document.title = t("GLOBAL_ACCOUNT_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box p-0">
            <Row>
              <Col md={6}>
                <Breadcrumbs
                  title={t("TPS_SOLUTIONS")}
                  breadcrumbItem={t("GLOBAL_ACCOUNTS")}
                  mainTitleLink="/"
                  titleLink="#"
                />
              </Col>
            </Row>
          </div>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row className="mt-0">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        nick_name: "",
                        payment_methods: "",
                        country_code: "",
                        currency: "",
                        request_id: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                          <Col md={12} className="mb-3">
                            <Row>
                              <Col md={24}>
                                <h5 className="text-dark-color mb-3">
                                  {t("CREATE_GLOBAL_ACCOUNTS")}
                                </h5>
                              </Col>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="nick_name">
                                    {t("NICK_NAME")}
                                    <sup className="text-danger"> *</sup>
                                  </Label>
                                  <FormTextField
                                    name="nick_name"
                                    id="nick_name"
                                    placeholder={t("NICK_NAME")}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="country_code">
                                    {t("COUNTRY")}
                                    <sup className="text-danger"> *</sup>
                                  </Label>
                                  <FormDropdown
                                    name="country_code"
                                    id="country_code"
                                    optionsValues={countries}
                                    changeDropdown={changeCountryHandler}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="currency">
                                    {t("CURRENCY")}
                                    <sup className="text-danger"> *</sup>
                                  </Label>
                                  <FormDropdown
                                    name="currency"
                                    id="currency"
                                    optionsValues={currencies}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="payment_methods">
                                    {t("PAYMENTS_METHODS")}
                                    <sup className="text-danger"> *</sup>
                                  </Label>
                                  <FormDropdown
                                    name="payment_methods"
                                    id="payment_methods"
                                    optionsValues={paymentsMethods}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            md={12}
                            className="d-flex justify-content-end align-items-center gap-3"
                          >
                            <Button className="btn-primary w-md" type="submit">
                              {loading ? (
                                <Spinner
                                  size="sm"
                                  style={{ marginRight: "10px" }}
                                >
                                  Loading...
                                </Spinner>
                              ) : (
                                ""
                              )}{" "}
                              {t("CREATE")}
                            </Button>
                          </Col>
                        </Form>
                      )}
                    </Formik>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {t("GLOBAL_ACCOUNTS")}
                  </CardTitle>

                  {/*<MDBDataTable responsive striped bordered data={data} />*/}
                  <MaterialTableCustom
                    data={globalAccounts}
                    columns={columns}
                    loading={loading}
                    tableRowDeleteHandler={tableRowDeleteHandler}
                    tableRowViewHandler={tableRowViewHandler}
                    clickDownloadButton={downloadStatement}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <>
        <Modal isOpen={openCreateGlobalAccountModal} centered={true} toggle={createGlobalAccountToggleModal}>
          <ModalHeader toggle={null}><span className="text-warning">{t("ALERT")}</span></ModalHeader>
          <ModalBody>
            {`${t("FOR_EVERY_GLOBAL_ACCOUNT_THERE_IS_A_CHARGE_OF")} $${GLOBAL_ACCOUNT_FEE}. ${t("FOR_EVERY_ADDITIONAL_GLOBAL_ACCOUNT_THERE_IS_A_CHARGE_OF")} $${ADDITIONAL_GLOBAL_ACCOUNT_FEE} ${t("AFTER_THE_5TH_GLOBAL_ACCOUNT")}`}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-primary" onClick={confirmCreateGlobalAccount}>
              {t("ACCEPTED")}
            </Button>{' '}
            {/*<Button color="secondary" onClick={createGlobalAccountToggleModal}>*/}
            {/*  {t("NO")}*/}
            {/*</Button>*/}
          </ModalFooter>
        </Modal>
      </>



      <>
                    <Modal isOpen={openDeleteModal} centered={true} toggle={deleteToggleModal}>
                        <ModalHeader toggle={null}>Confirmation</ModalHeader>
                        <ModalBody>
                            {t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GLOBAL_ACCOUNT")}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={confirmDelete}>
                                {t("YES")}
                            </Button>{' '}
                            <Button color="secondary" onClick={deleteToggleModal}>
                                {t("NO")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>

                <>
                    <Modal isOpen={openWarningModal} centered={true} toggle={deleteToggleModal}>
                        <ModalHeader toggle={null}><span className="text-warning">{t("ALERT")}</span></ModalHeader>
                        <ModalBody>
                            {t("CANNOT_DELETE_INACTIVE_GLOBAL_ACCOUTS")}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={deleteToggleModal}>
                                {t("CLOSE")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>

      {openModel ? (
        <GlobalAccountModel
          isOpen={openModel}
          rowData={selectedGlobalAccount}
          loading={loading}
          updateData={updateGlobalAccountData}
          toggleModal={() => {
            setModelOpen(false);
            setSelectedGlobalAccount(null);
          }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default GlobalAccounts;
