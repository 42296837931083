//SIGN_UP
export const POST_SIGNIN = "/tps-auth-service/api/auth/authenticate"
export const POST_SIGNUP_REGISTER = "/user-management-service/api/user/register"
export const POST_SIGNUP_USER_VALIDATE = "/user-management-service/api/user/validate-user"


export const TOKEN_VERIFY = "/tps-auth-service/api/auth/validate-token"
// export const POST_SIGNUP_PROFILE = "/user-management-service/api/user/register?step=2" 
// export const POST_SIGNUP_SECURITY_QUESTION = "/user-management-service/api/user/register?step=3"
// export const SIGN_UP_BUSINESS_DATA = "/user-management-service/api/user/register?step=4"
// export const SIGN_UP_DIRECTORS_DATA = "/user-management-service/api/user/register?step=5"

export const SIGN_UP_DATA_RETRIVE = "/user-management-service/api/user/get-user?email="

//forget password
export const SEND_OTP_TO_PHONE_AND_VERIFY = "/tps-notification-service/api/otp/send-registration-otp"
export const SEND_OTP_TO_PHONE = "/tps-notification-service/api/otp/send"
export const OTP_VERIFICATION = "/tps-notification-service/api/otp/verify?"
export const ANSWER_SECURITY_QUESTIONS = "/user-management-service/api/user/security-questions-answers"
export const CHANGE_PASSWORD = "/user-management-service/api/user/change-password"
export const GET_SECURITY_QUESTION_BY_ID = "/user-management-service/api/user/security-questions-by-user-id?userId="



//Common
export const GET_COUNTRIES = "/tps-master-service/api/master/country"
export const GET_TITLES = "/tps-master-service/api/master/user"
export const GET_SECURITY_QUESTION = "/user-management-service/api/user/security-questions"
export const GET_BANK_CODE = "/tps-master-service/api/master/bankCode"
export const GET_BANK_CURRENCY = "/tps-master-service/api/master/bankCurrency"
export const GET_CITY = "/tps-master-service/api/master/city"
export const GET_ID_TYPE = "/tps-master-service/api/master/id_type"
export const GET_GLOBAL_ACCOUNTS_BALANCE = "/tps-account-management-service/api/global-account/account-balance/"
export const GET_EXPENCES_PERCENTAGE = "/tps-account-management-service/api/global-account/expenses/"

// Payee

export const POST_PAYEE_REGISTER_DATA = "/tps-account-management-service/api/payee"
export const GET_PAYEE_STATUS = "/tps-master-service/api/master/payee-status"
export const GET_PAYEE_PURPOSE = "/tps-master-service/api/master/payee-purpose"
export const GET_PAYEE_REGISTER_DATA = "/tps-account-management-service/api/payee/find-by-co-user-id"
export const POST_PAYEE_TRANSACTION_HISTORY = "/tps-transaction-management-service/api/transactions/search"
export const GET_PAYEE_TRANSACTION_HISTORY = "/tps-transaction-management-service/api/transactions/fetch-all/by-co-user"
export const GET_REGISTER_PAYEE_BY_ID = "/tps-account-management-service/api/payee/find-by-id"
export const GET_PAYEE_BY_PAYEE_ID = "/tps-account-management-service/api/payee/find-by-payee-id"
export const PUT_PAYEE_REGISTER_DATA = "/tps-account-management-service/api/payee/update"
export const DELETE_PAYEE_REGISTER_DATA = "/tps-account-management-service/api/payee/delete"

export const GET_PAYEE_TRANSACTION_HISTORY_BY_ID = "/tps-transaction-management-service/api/fund-transfer/get-by-payee-id?payeeId="


// Source of Funds
export const POST_SOURCE_OF_FUNDS = "/tps-account-management-service/api/source/save"
export const GET_SOURCE_OF_FUNDS = "/tps-account-management-service/api/source/get-by-user?coUserId="
export const GET_CURRENCY_CONVERT_DATA = "/tps-transaction-management-service/api/currency-convert"
export const GET_SOURCE_OF_FUNDS_PURPOSE = "/tps-master-service/api/master/purpose"
export const SAVE_SOURCE_OF_FUNDS_REGISTER_PAYEE = "/tps-transaction-management-service/api/fund-transfer/save"
export const SAVE_SOURCE_OF_FUNDS_NEW_PAYEE = "/tps-transaction-management-service/api/fund-transfer/save-for-unregistered-payee"

export const AIRWALLEX_UPDAYE_KYC_STATUS = "/user-management-service/api/kyc/save-data"


//profile
export const UPDATE_USER_DETAILS = "/user-management-service/api/user/update-user"
export const UPDATE_PASSWORD_WITH_CURRENT_ONE = "/user-management-service/api/user/update-password"
export const SAVE_SECURITY_QUESTION_FIRST_TIME = "/user-management-service/api/user/save-sequrity-question-answers"
export const UPDATE_SECURITY_QUESTIONS_AND_ANSWERS = "/user-management-service/api/user/update-sequrity-question-answers" 
export const CHANGE_USER_LANGUGE = "/user-management-service/api/user/update-user"

//global account
export const SAVE_GLOBAL_ACCOUNT = "/tps-account-management-service/api/global-account?coUserId="
export const UPDATE_GLOBAL_ACCOUNT = "/tps-account-management-service/api/global-account/update-global-account?coUserId="
export const GET_GLOBAL_ACCOUNTS = "/tps-account-management-service/api/global-account/find-by-co-user-id?coUserId="

export const DELETE_GLOBAL_ACCOUNTS = "/tps-account-management-service/api/global-account/close-global-account?airwallexGlobalAccountId="
export const DOWNLOAD_GLOBAL_ACCOUNT_STATEMENT = "/tps-account-management-service/api/global-account/get-global-account-statement"

//Inquiry
export const GET_INQUIRY_BY_CO_USER_ID = "/user-management-service/api/inquiry/get-by-coUser-id?coUserId="

export const POST_INQUIRY = "/user-management-service/api/inquiry/save"