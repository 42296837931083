import React, { useMemo, useState } from "react";
import {Container, Row, Col, Button, Card, CardBody, Badge, Spinner, Modal, ModalBody} from "reactstrap";
import { Base64 } from "js-base64";
import { useEffect } from "react";
import { init } from "@airwallex/components-sdk";
import { createElement } from "@airwallex/components-sdk";
import { useSelector } from "react-redux";
import { postAirwallex } from "../../helpers/api_helper";
import {
  airWallexUpdateKycState,
  getExpencesPercentage,
  getGlobalAccountBalance,
} from "../../services/CommonService";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Chip } from "@material-ui/core";
import { PieChart } from "@mui/x-charts/PieChart";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {
  getPayeeTransactionHistory,
} from "../../services/PayeeService";
import PayeeTransactionEditModal from "../Payee/components/payeeTransactionEditModal";
import { axiosApiAirwallex} from "../../helpers/api_helper"

function DashBoard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  let element = null;

  //temp
  const [codeChallenge, setCodeChallenge] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [authorizeCode, setAuthorizeCode] = useState(null);
  const [codeVerify, setCodeVerify] = useState(null);
  const [showKycDiv, setShowKycDiv] = useState(false);
  const [currentBalances, setCurrentBalances] = useState([]);
  const [expencesPercentage, setExpencePercentage] = useState([]);
  const [modal, setModal] = useState(false);
  const [openNetworkErrorModel, setOpenNetworkErrorModel] = useState(false)
  const [isKycAgree, setIsKycAgree] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [clickedRowData, setClickedRowData] = useState(null);
  const user = useSelector((state) => state.auth.data.userDTO);


  const isAccountActive = user?.isActive;
  const airwallexSubmitStatus = user?.kycSubmitStatus;

  const [
    isOpenPayeeTransactionHistoryModal,
    setIsOpenPayeeTransactionHistoryModal,
  ] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isChartLoading, setChartLoading] = useState(false);
  
  const toggleTransactionHistoryModal = () =>
    setIsOpenPayeeTransactionHistoryModal(!isOpenPayeeTransactionHistoryModal);



  const [columns, setColumns] = useState([
    { title: t("DATE_&_TIME"), field: "date_and_time", width: 200 },
    { title: t("PAYEE_NAME"), field: "payee_name", width: 200 },
    { title: t("PAID_CURRENCY"), field: "paid_currency", width: 200 },
    { title: t("PAID_AMOUNT"), field: "paid_amount", width: 200 },
    { title: t("PAYEE_CURRENCY"), field: "payee_currency", width: 200 },
    { title: t("TRANSFERRED_AMOUNT"), field: "transfer_amount", width: 200 },
    { title: t("STATUS"), field: "payee_transaction_status", width: 50 },
  ]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (isAccountActive) {
      fetchPayeeTransactionHistory();
      fetchCurrentBalances();
      fetchExpencesPercentage();
    }else{
      generateCodeChallengeAndGetAuthToken()
    }
  }, []);



  useEffect(() => {
    if (authToken) {
      fetchAuthorizationCode();
    }

  }, [authToken]);


  const generateCodeChallengeAndGetAuthToken = async () => {

    try {
      const dec2hex = (dec) => {
        return ("0" + dec.toString(16)).slice(-2);
      };

      const generateCodeVerifier = () => {
        // generate random length for code_verifier which should be between 43 and 128
        const length = Math.random() * (129 - 43) + 43;
        const array = new Uint32Array(length / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec2hex).join("");
      };

      const codeVerifier1 = generateCodeVerifier();
      setCodeVerify(codeVerifier1);

      // Generate code_challenge
      const sha256 = (plain) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest("SHA-256", data);
      };
     

      const base64urlencode = (hashed) => {
        const bytes = new Uint8Array(hashed);
        const base64encoded = Base64.fromUint8Array(bytes, true);
        return base64encoded;
      };

      const generateCodeChallengeFromVerifier = async (codeVerifier1) => {
        const hashed = await sha256(codeVerifier1);
        const base64encoded = base64urlencode(hashed);
        return base64encoded;
      };

      const codeVerifier = await generateCodeChallengeFromVerifier(
        codeVerifier1
      );
      setCodeChallenge(codeVerifier);

      const { token } = await postAirwallex(
        "authentication/login",
        []
      );

      // Set the new token in the airwallex headers
      axiosApiAirwallex.defaults.headers['Authorization'] = `Bearer ${token}`;

      localStorage.setItem("airWallexToken", JSON.stringify(token));
      setAuthToken(token);
    } catch (error) {
      // toast.error("Error")
    }
  };

  const fetchAuthorizationCode = async () => {

    try {

      const respons = await postAirwallex("authentication/authorize", {
        code_challenge: codeChallenge,
        scope: ["w:awx_action:onboarding"],
      }
      );
      setAuthorizeCode(respons.authorization_code);
    } catch (error) {
     // toast.error("Error");
    }
  };



  const handleReady = (event) => {
    if (event.kycStatus !== "INIT") {
      switch (event.kycStatus) {
        case "SUBMITTED":
          submitSuccessForm(event);
          window.location.reload()
          // render SUBMITTED kycStatus page
          break;
        case "SUCCESS":
          // render SUCCESS kycStatus page
          submitSuccessForm(event);
          break;
        case "FAILURE":
          // render FAILURE kycStatus page
          submitSuccessForm(event);
          break;
        default:
          // handle other cases if needed
          break;
      }

    } else {
      // remove loading state
      // setLoading(false);
    }
  };

 
  const fetchCurrentBalances = async () => {
    const coUserId = user?.coUserId;
    try {
      const respons = await getGlobalAccountBalance(coUserId);
      setCurrentBalances(respons?.result);
    } catch (error) {
      //error massage
    }
  };

  const fetchExpencesPercentage = async () => {
    setChartLoading(true)
    const coUserId = user?.coUserId;
    try {
      const { result } = await getExpencesPercentage(coUserId);
      const data = result.map((result, index) => {
        return {
          id: index,
          value: result?.percentage,
          label: result?.reason,
        };
      });
      setExpencePercentage(data);
      setChartLoading(false)
    } catch (error) {
      //error massage
      setChartLoading(false)
    }
  };

  const fetchPayeeTransactionHistory = async () => {
    setLoading(true);

    let data = {
      co_user_id: user ? user.coUserId : "",
    };

    try {
      const response = await getPayeeTransactionHistory(data);

      

      const sortedData = response.result.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      let registerData = sortedData.map((registerData) => {
        let formattedDate = "";

        if (registerData.completed_date) {
          // Parse the date string and format it
          const parsedDate = new Date(registerData.completed_date);

          // Format the hours and minutes with leading zeros
          const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
          const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

          formattedDate = `${parsedDate.getFullYear()}-${
            parsedDate.getMonth() + 1
          }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
        } else {
          formattedDate = "";
        }

        let errorMessage = registerData.error_message;

        if (errorMessage && errorMessage.includes("beneficiary.address.")) {
          // Extract street_address from the error message
          errorMessage = errorMessage.replace("beneficiary.address.", "");
        }

        // if (errorMessage){
        //     errorMessage = `ERROR : ${errorMessage}`
        // }

        return {
          date_and_time: formattedDate,
          payee_name: registerData.payee_nick_name,
          paid_currency: registerData.fee_currency,
          paid_amount: registerData.payment_amount,
          payee_transaction_status: registerData.status,
          purpose: registerData.reason,
          paid_by: registerData.fee_paid_by,
          conversion_Currency: registerData.conversion_currency,
          payee_account_number: registerData.payee_account_number,
          errorMessage: errorMessage,

          payment_id :  registerData.payment_id,
// paid_by
          transfer_fee_amount : registerData.transfer_fee_amount,
          fee_currency : registerData.fee_currency,
          transfer_amount : registerData.transfer_amount,
          payment_method : registerData.payment_method,
          bank_country : registerData.bank_country,
          payee_currency : registerData.payee_currency,
        };
      });

  
      const modifiedData = registerData.map((item) => {
        // Check payee_transaction_status and add the appropriate Badge
        const badgeColor =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "bg-warning"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "bg-custom-success"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "bg-danger"
            : item.payee_transaction_status.toLowerCase() ===
              "ready_for_dispatch"
            ? "bg-ready-for-dispatch"
            : item.payee_transaction_status.toLowerCase() === "in_review"
            ? "bg-in-review"
            : item.payee_transaction_status.toLowerCase() === "dispatched"
            ? "bg-dispatched"
            : "bg-success";
        const badgeText =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "PENDING"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "APPROVED"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "FAILED"
            : `${item.payee_transaction_status.toUpperCase()}`;

        return {
          ...item,
          payee_transaction_status: (
            <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>
          ),
        };
      });

      const newResponseData = modifiedData.slice(0,10);

      setData(newResponseData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //toast.error(t("ERROR_FETCH_TRANSACTION_HISTORY"));
    }
  };

  const submitSuccessForm = (event) => {
    try {
      const data = {
        approved: true,
        approvedAt: null,
        approvedBy: null,
        businessEmail: user.businessEmail,
        coUserId: user.coUserId,
        firstName: user.firstName,
        kycStatus: 'SUBMITTED',
        lastName: user.lastName,
        mobileNumber: user.mobileNumber,
        reviewComments: null,
        submissionAt: null,
        submissionId: null,
      };
      const respons = airWallexUpdateKycState(data);
      setTimeout(()=>{
        window.location.reload()
      },4000)
    } catch (error) {
      toast.error("Error : Airwallex Status Update");
    }
  };



  const componentLoad = async () => {

    try {
      setShowKycDiv(true);
      const options = {
        elements: ["kyc"],
        langKey: "en",
        env: process.env.REACT_APP_AIRWALLEX_ENV,
        authCode: authorizeCode,
        clientId: process.env.REACT_APP_AIRWALLEX_CLIENT_ID,
        codeVerifier: codeVerify,
      };
  
      await init(options);
  
      const options2 = {
        hideHeader: true,
        hideNav: true,
      };
  
      element = await createElement("kyc", options2);
      element.mount("kyc");
  
      element.on("ready", (data) => {
        handleReady(data)
      });
      element.on("success", (data) => {
        submitSuccessForm()
      });
      element.on("submitted", (data) => {
        //handleReady(data)
      });
      element.on("cancel", (data) => {
        handleReady(data)
      });
     
    } catch (error) {
    
      toast.error('Network Error')

        setTimeout(() => {
          window.location.reload()
        }, 3000);
    }
   
  };


  document.title = t("DASHBOARD_TPS_SOLUTIONS");


  const tableRowViewHandler = (rowData) => {
    console.log('rowData ',rowData)
    // Save the clicked row data
    setClickedRowData(rowData);
    toggleTransactionHistoryModal();
  };

  const toggleModel = () => setModal(!modal);

  const kycSubmitHandler = () => {
    if(isKycAgree === false){
      setModal(true)
    }else{
      componentLoad()
    }

  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleContinueClick = () => {
    if(isChecked == true){
      setIsKycAgree(true)
      setModal(false)
      componentLoad()
    }
  };
  const pieChartColors = ['#032370', '#F8C440', '#03BEE1', '#BE03F0', '#FFFF33','#001333','#8b5cf6','#4ade80','#F4D5FC','#FCEBBF'];

  return (
    <React.Fragment>
      <div className="page-content">
        {showKycDiv ? (
          <div
            id="kyc"
            style={{
              position: "relative",
              width: "calc(100% + 38px)",
              height: "100vh",
              left: "-12px",
            }}
          ></div>
        ) : (
          <Container fluid>

                  <div className="page-title-box p-0">

                    <Row>
                      <Col md={6}>
                        <Breadcrumbs
                            title={t("WELCOME_TO_TPS_SOLUTIONS")}
                            breadcrumbItem={t("Dashboard")}
                            mainTitleLink="/"
                            titleLink="#"
                        />
                      </Col>
                      {user?.isActive ? (
                          <Col
                              md={6}
                              className="d-flex justify-content-md-end py-3 py-md-4"
                          >
                            <Button
                                color="primary w-md h-max"
                                style={{ paddingLeft : "40px", paddingRight : "40px" ,paddingTop : "10px", paddingBottom : "10px" }}
                                onClick={() => {
                                  navigate("/global-accounts");
                                }}
                            >
                              <span style={{ fontWeight : "bold", fontSize: "18px"}}>{t("CREATE_GLOBAL_ACCOUNTS")}</span>
                            </Button>
                          </Col>
                      ) : (
                          <Col
                              md={6}
                              className="d-flex justify-content-md-end pt-0 pt-sm-3 pt-md-3 py-3 py-md-4"
                          >
                            {isAccountActive ? (
                                <Button
                                    color="primary w-md h-max"
                                    onClick={() => {
                                      navigate("/global-accounts");
                                    }}
                                >
                                  {t("CREATE_GLOBAL_ACCOUNTS")}
                                </Button>
                            ) : (

                                airwallexSubmitStatus == "SUBMITTED" ? (
                                    <Button
                                        color="primary w-md h-max"
                                        onClick={componentLoad}
                                        disabled={true}
                                    >
                                      {t("KYC_SUBMITTED")}
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary w-md h-max"
                                        onClick={kycSubmitHandler}
                                        disabled={false}
                                    >
                                      {t("KYC_SUBMIT")}
                                    </Button>
                                )


                            )}
                          </Col>
                      )}
                    </Row>
                  </div>

                  <Row>
                    <Col md={12} className="mb-4">
                      <h4 className="text-dark-color mb-2">
                        Hi! {`${user.firstName} ${user.lastName}`}
                      </h4>
                      {isAccountActive === true &&
                      airwallexSubmitStatus == "ACTIVE" ? (
                          <Chip
                              label={t("YOUR_BUSINESS_IS_A_VERIFIED_AND_ACCOUNT_IS_ACTIVE")}
                              className="primary-font-color fw-medium bg-white"
                              style={{ border: "1px solid #03BEE1" }}
                          />
                      ) : isAccountActive === false &&
                      airwallexSubmitStatus == "PENDING" ? (
                          <Chip
                              label={t("VERIFY_YOUR_BUSINESS_TO_ACTIVATE_YOUR_ACCOUNT")}
                              className="primary-font-color fw-medium bg-white"
                              style={{ border: "1px solid #03BEE1" }}
                          />
                      ) : isAccountActive === false &&
                      airwallexSubmitStatus == "SUBMITTED" ? (
                              <Chip
                                  label={t("YOUR_APPLICATION_IS_STILL_IN_THE_PROGRESS_COUNTING_FROM_NOW")}
                                  className="primary-font-color fw-medium bg-white"
                                  style={{ border: "1px solid #03BEE1" }}
                              />
                          ) :
                          isAccountActive === false &&
                          airwallexSubmitStatus == "FAILED" ? (
                                  <Chip
                                      label={t("ACCOUNT_ACTIVATION_FAILED_PLEASE_RESUBMIT_KYC")}
                                      className="primary-font-color fw-medium bg-white"
                                      style={{ border: "1px solid #03BEE1" }}
                                  />
                              ) :
                              (
                                  ""
                              )
                      }
                    </Col>

                    <Col md={12} lg={12} xl={6}>
                      <h6 className="secondary-font-color">{t("DISCOVER_FEATURES")}</h6>

                      <Row>
                        <Col md={6}>
                          <Link to="/payee">
                            <Card className="mb-3">
                              <CardBody className="d-flex flex-wrap justify-content-between align-items-center gap-4">
                                <Avatar className="text-dark bg-white f-32">
                                  <i className="ti-user"></i>
                                </Avatar>
                                <div className="text-end">
                                  <p className="m-0 font-size-14">{t("PAYEE_INFO")}</p>
                                  <p className="m-0 font-size-12 secondary-font-color">
                                    {t("CLICK_HERE")}
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>

                        <Col md={6}>
                          <Link to="/funds-transfer">
                            <Card className="mb-3">
                              <CardBody className="d-flex flex-wrap justify-content-between align-items-center gap-4">
                                <Avatar className="text-dark bg-white f-32">
                                  <i className="ti-share"></i>
                                </Avatar>
                                <div className="text-end">
                                  <p className="m-0 font-size-14">{t("FUND_TRANSFER")}</p>
                                  <p className="m-0 font-size-12 secondary-font-color">
                                    {t("CLICK_HERE")}
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <h6 className="secondary-font-color">{t("EXPENSES")}</h6>



                          <Row>
                            <Col md={12}>
                              <Card>
                                <CardBody className="d-flex flex-wrap justify-content-center px-1" style={{ minHeight: '180px' }}>


                                  {
                                    isChartLoading ? (
                                            <Spinner color="info" className="d-flex mt-5 justify-content-center align-items-center">
                                              Loading...
                                            </Spinner>
                                        ) :
                                        (
                                            <>
                                              <h6 className="">{t('AMOUNT_$')}</h6>
                                              <PieChart
                                                  className="w-100"
                                                  series={[
                                                    {
                                                      data: expencesPercentage,
                                                    },
                                                  ]}
                                                  height={200}
                                                  width={650}
                                                  colors={pieChartColors}
                                              />
                                            </>

                                        )
                                  }


                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                    </Col>

                    <Col md={12} lg={12} xl={6}>
                      <h6 className="secondary-font-color">{t("YOUR_CURRENT_BALANCE")}</h6>

                      <Row>
                        {currentBalances.map((balance) => {
                          return (
                              <Col md={6}>
                                <Card className="mb-3">
                                  <CardBody className="d-flex flex-wrap justify-content-between align-items-center gap-3">
                                    <Avatar alt="Country Flag" src={balance.flag} />
                                    <div className="text-end">
                                      <h5 className="mb-0 text-dark">
                                        {balance.total}
                                      </h5>
                                      <p className="m-0">{balance.currency_code}</p>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                          );
                        })}
                      </Row>
                    </Col>

                    <Col md={12}>
                      <div className="d-flex align-items-center gap-3 mb-2">
                        <h6 className="secondary-font-color m-0">
                          {t("TRANSACTION_HISTORY")}
                        </h6>
                        <Link to="/payee-transaction-history">
                          <Button
                              className="btn-white w-md text-muted"
                              type="button"
                              style={{ backgroundColor: "white" }}
                          >
                            {t("VIEW_MORE")}
                          </Button>
                        </Link>
                      </div>

                      <Card>
                        <CardBody>
                          <div className="custom-table">
                            <MaterialTableCustom
                                tableTitle="Dashboard Table"
                                data={isAccountActive ? data : []}
                                columns={columns}
                                loading={loading}
                                tableRowViewHandler={
                                  isAccountActive ? tableRowViewHandler : null
                                }
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>


            {isOpenPayeeTransactionHistoryModal && (
              <PayeeTransactionEditModal
                rowData={clickedRowData}
                isOpen={isOpenPayeeTransactionHistoryModal}
                toggleModal={toggleTransactionHistoryModal}
              />
            )}
          </Container>
        )}

        {/*Safeguard Modal*/}
      <Modal isOpen={modal} toggle={toggleModel} centered>
        {/*Modal Body*/}
        <ModalBody className="text-center p-0">
          {/*Modal title*/}


          <div className="w-100 px-3 pb-3">
            {/*Modal Text*/}
            <p className="mb-2 font-size-14 mt-3">
              {/*{t("I_AGREE_TO_AIRWALLEX_TREASURY_AND_MANAGEMENT_TERMS_PRIVACY_POLICY_AND_SCALE_USER_TERMS")}*/}
            </p>

            {/*Modal Text*/}
            {/*<p className="mb-2 font-size-14">*/}
            {/*  {t("OTP_IS_NOT_TO_BE_SHARED_WITH_ANYONE_TO_PREVENT_MISUSE")}*/}
            {/*</p>*/}

            {/*Modal Text*/}
            {/*<p className="mb-3 font-size-14">*/}
            {/*  {t("FIND_OUT_MORE_ON_OUR_WEBSITE")}*/}
            {/*</p>*/}

            {/*Modal Checkbox*/}
            <div className="d-flex justify-content-center">
              <div className="p-3 modal-header-bg w-fit rounded-2">
                <div className="form-check">

                  
                    <h3>{t("TERMS_AND_CONDITIONS")}</h3>
{/*                   
                    <a href="https://www.airwallex.com/sg/terms/privacy-policy#scope-of-policy" target="_blank">Privacy Policy | Airwallex</a><br></br>
                    <p>Airwallex is committed to protecting your data, privacy and financial details, in accordance with the jurisdictions regulations within which we operate.
                    airwallex.com</p><br></br>
                    <a href="https://www.airwallex.com/sg/terms/tps" target="_blank">TPS Scale User Terms SG | Airwallex</a><br></br>
                    <p>Click here to read the full HitPay Scale User Terms</p><br></br> */}

                    <label
                      className={`form-check-label font-size-14 m-0 `}
                      htmlFor="customControlInline"
                  >
                    {/*{t("READ_AND_UNDERSTAND")}*/}
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    /> 
                    {/* {t("I_AGREE_TO_AIRWALLEX_TREASURY_AND_MANAGEMENT_TERMS_PRIVACY_POLICY_AND_SCALE_USER_TERMS")} */}
                      {t("I_AGREE_TO_AIRWALLEX")} <a href="https://www.airwallex.com/sg/terms/treasury-management-terms" target="_blank">{t("TREASURY_AND_MANAGEMENT_TERMS")}</a>, <a href="https://www.airwallex.com/sg/terms/privacy-policy#scope-of-policy" target="_blank">{t("PRIVACY_POLICY")}</a> , {t("AND")} <a href="https://www.airwallex.com/sg/terms/tps" target="_blank">{t("SCALE_USER_TERMS")}</a>.
                  </label>

                </div>
              </div>
            </div>
            {/*Modal Button*/}
            <div className="d-flex justify-content-center mt-3 mb-1">
              <button
                  className="btn btn-primary w-lg waves-effect waves-light"
                  type="submit"
                  onClick={handleContinueClick}
              >
                Continue
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>


        {/*Airwallex NetWork Error Modal*/}
        <Modal isOpen={openNetworkErrorModel} toggle={() => {
          setOpenNetworkErrorModel(false)
          window.location.reload()
        }} centered>
        <ModalBody className="text-center p-0">
          <div className="w-100 px-3 pb-3">
            <p className="mb-2 font-size-14 mt-3">
              {/*{t("I_AGREE_TO_AIRWALLEX_TREASURY_AND_MANAGEMENT_TERMS_PRIVACY_POLICY_AND_SCALE_USER_TERMS")}*/}
            </p>

            <div className="d-flex justify-content-center">
              <div className="p-3 modal-header-bg w-fit rounded-2">
                <div className="form-check">

                  <label
                      className={`form-check-label font-size-14 m-0 text-danger`}
                      htmlFor="customControlInline"
                  >
                    NetWork Error 
                  </label>


                </div>
              </div>
            </div>

            {/*Modal Button*/}
            <div className="d-flex justify-content-center mt-3 mb-1">
              <button
                  className="btn btn-primary w-lg waves-effect waves-light"
                  type="submit"
                  onClick={() => {
                    window.location.reload()
                  }}
              >
                Continue
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>        



      </div>
    </React.Fragment>
  );
}

export default DashBoard;
