import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Base64 } from "js-base64";
import { init, createElement } from '@airwallex/payouts-web-sdk';
import { postAirwallex, axiosApiAirwallex } from "../../helpers/api_helper";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import FormImageUploader from "../../common/formComponents/FormImageUploader";
import {
  getPayeeDetailsBypayeeId,
  getPayeeRegisterData,
} from "../../services/PayeeService";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from 'classnames';
import {
  getQuoteByUser,
  saveSourceOfFundsRegisterPayee,
  validateFundTransfer
} from "../../services/SourceOfFunds";
import { v4 as uuidv4 } from 'uuid';
import PaymentValidateModal from "./paymentValidateModal";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import MaterialTableCustomSmall from "../../common/tables/MaterialTableCustomSmall";

const RegisteredPayeeComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registeredPayees, setRegisteredPayees] = useState([]);
  const [selectedPayee, setSelectedPayee] = useState([]);
  const [purposeOfTransfer, setPurposeOfTransfer] = useState([]);
  const [transactionPayload, setTransactionPayload] = useState({});
  const [payOutRequest, setPayOutRequest] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [swiftOptions, setSwiftOptions] = useState([
    { label: "SHA", value: "SHARED" },
    { label: "OUR", value: "PAYER" }
  ]);
  const [activeTab, setActiveTab] = useState(1);

  const payoutFormRef = useRef(null);
  const payoutComponentElementRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  const user = useSelector((state) => state.auth.data?.userDTO);
  const isAccountActive = user?.isActive;
  const isAuthorized = user?.isAuthorized;

  const [secondLevelAuth, setSecondLevelAuth] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const payee_id = queryParams.get("payee_id");

  const [isOTPModal, setIsOTPModal] = useState(false);
  const [quoteList, setQuoteList] = useState([]);
  const [quoteData, setQuoteData] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);

  const handleModalCancel = async =>{
    setIsOTPModal(false);
  }

  const handleSave = async () => {
    await savePaymentData(payOutRequest)
  }

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([
    { title: t("QUOTE_ID"), field: "quoteId", width: 200 },
    { title: t("BUY_CURRENCY"), field: "buyCurrency", width: 200 },
    { title: t("SELL_CURRENCY"), field: "sellCurrency", width: 200 },
    { title: t("VALID_TO"), field: "formattedValidToAt", width: 200 },
    { title: t("RATE"), field: "rate", width: 200 },
  ]);

  useEffect(() => {
    if (isAccountActive === true) {
      fetchPayees();
    }
  }, [isAccountActive]);

  useEffect(() => {
    if(payee_id){
      changePayeeForParams(payee_id);
    }
  }, [payee_id]);

  const changePayeeForParams = async (payee_id) => {
    try {
      const data = {
        payee_id: payee_id,
      };
      const response = await getPayeeDetailsBypayeeId(data);
      initAndRenderElement(response.result[0].beneficiary_id, null);
      setSelectedPayee(response.result[0]);
      // await getQuoteByCoUser(response.result[0]);
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  const fetchPayees = async () => {
    try {
      const data = {
        co_user_id: user.coUserId,
      };
      const response = await getPayeeRegisterData(data);
      const payees = response.result.map((payee) => {
        if (payee.payee_type === "PERSONAL") {
          return {
            value: payee.payee_id,
            label: payee.first_name && payee.last_name
                ? `${payee.account_name} - ${payee.first_name} ${payee.last_name}`
                : payee.account_name, // Show only account_name if first_name or last_name is null
          };
        } else {
          return {
            value: payee.payee_id,
            label: payee.company_name
                ? `${payee.account_name} - ${payee.company_name}`
                : payee.account_name, // Show only account_name if company_name is null
          };
        }
      });
      setRegisteredPayees(payees);
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  const changePayee = async (value, form) => {
    try {
      setLoading(true);
      const data = {
        payee_id: value,
      };
      const response = await getPayeeDetailsBypayeeId(data);
      initAndRenderElement(response.result[0].beneficiary_id, null);
      setSelectedPayee(response.result[0]);
      // await getQuoteByCoUser(response.result[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  const changeQuote = async (value) => {
    // if(value === "Select an option"){
    //   await initAndRenderElement(selectedPayee.beneficiary_id, null)
    //   setTableData([])
    // } else {
    //   setSelectedQuote(value)
    //
    //   let filteredData = quoteList.filter(item => {
    //     return item.airwallexQuoteId === value;
    //   })
    //
    //   filteredData = filteredData.map((item) => {
    //     let formattedValidFromAt = "";
    //     let formattedValidToAt = "";
    //
    //     if(item.validToAt) {
    //       // Parse the date string and format it
    //       const parsedDate = new Date(item.validToAt);
    //
    //       // Format the hours and minutes with leading zeros
    //       const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
    //       const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);
    //
    //       formattedValidToAt = `${parsedDate.getFullYear()}-${
    //           parsedDate.getMonth() + 1
    //       }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
    //     } else {
    //       formattedValidToAt = "";
    //     }
    //
    //     if (item.validFromAt) {
    //       // Parse the date string and format it
    //       const parsedDate = new Date(item.validFromAt);
    //
    //       // Format the hours and minutes with leading zeros
    //       const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
    //       const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);
    //
    //       formattedValidFromAt = `${parsedDate.getFullYear()}-${
    //           parsedDate.getMonth() + 1
    //       }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
    //     } else {
    //       formattedValidFromAt = "";
    //     }
    //
    //     return {
    //       ...item,
    //       formattedValidFromAt,
    //       formattedValidToAt
    //     };
    //   });
    //   setTableData(filteredData)
    //   await initAndRenderElement(selectedPayee.beneficiary_id, value)
    // }

  }

  const getQuoteByCoUser = async (selectedPayee) => {
    try {
      setLoading(true)
      const response = await getQuoteByUser(user.coUserId);
      setQuoteList(response.result)
      console.log("response", response.result)
      const data = response.result
          .filter((item) => item.buyCurrency === selectedPayee.bank_currency)
          .map((item) => ({
            value: item.airwallexQuoteId,
            label: `${item.quoteId} - ${item.buyCurrency} ${item.sellCurrency}`,
          }));
      console.log("data", data)
      setQuoteData(data);
    } catch (error) {
      toast.error(t("Failed to load quotes"));
    } finally {
      setLoading(false)
    }
  };

  const validationSchemaPayerData = yup.object().shape({
    payee_id: yup.string().required(t("PAYEE_NAME_IS_REQUIRED")),
  });

  const validationSchemaAdditionalData = yup.object().shape({
    reference: yup.string().required(t("REFERENCE_NAME_IS_REQUIRED")),
    purpose: yup.string().required(t("PURPOSE_NAME_IS_REQUIRED")),
  });

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const payeeDataNext = () => {
    toggleTab(2);
  }

  const getTransactionPayload = async () => {
    if (payoutComponentElementRef.current) {
      setLoading(true);
      const formResult = await payoutComponentElementRef.current.submit();
      console.log("formResult", formResult);

      if (!formResult.errors) {
        try {
          const response = await validateFundTransfer(formResult.values, user.coUserId);
          console.log("response", response)
          if(response?.result?.SecondLevelAuth && user.isAuthorized){
            setSecondLevelAuth(response?.result?.SecondLevelAuth)
          }
          setPurposeOfTransfer(formResult.additionalInfo.reason);
          setTransactionPayload(formResult.values);
          toggleTab(3)
        } catch (error) {
          console.log("error", error)
          console.log("response", error.response)
          toast.error(error.response.data.result.error)
          // toast.error(t("ERROR_FETCH_PAYEE"));
        }
      }
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    let data = transactionPayload;
    data.reason = values.purpose;
    data.reference = values.reference;
    data.request_id = uuidv4()
    let request = {
      coUserId: user.coUserId,
      payeeId: selectedPayee.payee_id,
      isBeneficiary: false,
      beneficiary: null,
      payout: data
    }
    setPayOutRequest(request)


    setIsOTPModal(true);
    setLoading(false);
    // await savePaymentData(request)
    // try {
    //   const response = await saveSourceOfFundsRegisterPayee(request, user.coUserId);
    //   setLoading(false);
    //   toast.success(t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE_SUCCESS"));
    //   navigate("/payee-transaction-history");
    // } catch (error) {
    //   setLoading(false);
    //   if(error.response.data.errorType === "Airwallex side failure!"){
    //     toast.error(error.response.data.result.error.errors[0].source)
    //   } else {
    //     toast.error(t("ERROR_TRANSACTION_FAILED"));
    //   }
    // }
    setLoading(false);
  }

  const savePaymentData = async (request) => {
    setLoading(true);
    try {
      const response = await saveSourceOfFundsRegisterPayee(request, user.coUserId);
      setLoading(false);
      toast.success(t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE_SUCCESS"));
      navigate("/payee-transaction-history");
    } catch (error) {
      setLoading(false);
      if(error.response.data.errorType === "Airwallex side failure!"){
        toast.error(error.response.data.result.error.errors[0].source)
      } else {
        toast.error(t("ERROR_TRANSACTION_FAILED"));
      }
    }
    setLoading(false);
  }

  const generateCodeVerifier = async () => {
    const length = Math.floor(Math.random() * (129 - 43)) + 43;
    const array = new Uint32Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ("0" + dec.toString(16)).slice(-2)).join("");
  };

  const generateCodeChallengeFromVerifier = async (verifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const hashed = await window.crypto.subtle.digest("SHA-256", data);
    const bytes = new Uint8Array(hashed);
    return Base64.fromUint8Array(bytes, true);
  };

  const fetchAuthorizationCode = async (codeChallenge) => {
    try {
      const response = await postAirwallex("authentication/authorize", {
        code_challenge: codeChallenge,
        scope: ["w:awx_action:transfers_edit"],
      });
      return response.authorization_code;
    } catch (error) {
      console.error("Error fetching authorization code", error);
    }
  };

  const fetchAuthToken = async () => {
    try {
      const { token } = await postAirwallex("authentication/login", []);
      axiosApiAirwallex.defaults.headers['Authorization'] = `Bearer ${token}`;
      localStorage.setItem("airWallexToken", JSON.stringify(token));
      return token;
    } catch (error) {
      console.error("Error fetching auth token", error);
    }
  };



  const initAndRenderElement = async (beneficiary_id, quote) => {
    try {
      console.log("beneficiary_id", beneficiary_id)
      console.log("selectedQuote", quote)

      // let lockedCurrency = null;
      // if(quote){
      //   console.log("quoteList", quoteList)
      //   let filtered = quoteList.filter(item => {
      //     return item.airwallexQuoteId === quote;
      //   })
      //   console.log("filtered", filtered)
      //   lockedCurrency = filtered[0]?.sellCurrency
      // }
      // console.log("lockedCurrency", lockedCurrency)

      setLoading(true);
      await fetchAuthToken();
      const condeVerifier = await generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(condeVerifier);
      const authCode = await fetchAuthorizationCode(codeChallenge);

      const options = {
        langKey: "en",
        env: process.env.REACT_APP_AIRWALLEX_ENV,
        authCode: authCode,
        clientId: process.env.REACT_APP_AIRWALLEX_CLIENT_ID,
        codeVerifier: condeVerifier,
      };
      console.log("options", options)
      await init(options);


      const payoutComponentElement = await createElement('payoutForm', {
        defaultValues: {
          beneficiary_id: beneficiary_id, // use beneficiary id under the account
        },
        // customizations: {
        //   fields: {
        //     payment_amount: {
        //       disabled: true,
        //     },
        //   },
        // },

      });
      payoutComponentElementRef.current = payoutComponentElement;
      console.log("beneficiaryComponentlement", payoutComponentElement)
      if (payoutFormRef.current) {
        payoutComponentElement.mount(payoutFormRef.current); // Mount to the ref
      }

      payoutComponentElement.on('ready', () => {
        console.log("ready")
        setIsReady(true);
        // Handle after ready
      });

      payoutComponentElement.on('change', ({ value }) => {
        // console.log("change", value)
        // Handle when form value changes
      });

      payoutComponentElement.on('formState', ({ loading, validating, errors }) => {
        // console.log("formState", loading, validating, errors)
        // Handle when form state changes
      });

      setLoading(false);
    } catch (error) {
      console.error("Error initializing and rendering the element", error);
      toast.error('Network Error')
      // setTimeout(() => {
      //   window.location.reload()
      // }, 3000);
    }
  };

  // useEffect(() => {
  //   initAndRenderElement();
  // }, []);

  document.title = t("FUNDS_TRANSFER_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle={t('TPS_SOLUTIONS')}
            title={t("FUNDS_TRANSFER")}
            breadcrumbItem={t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE")}
            mainTitleLink="/"
            titleLink="/funds-transfer"
          />

          <Row>
            <Col md={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => toggleTab(1)}
                  >
                    {t("PAYEE_DETAILS")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 2 })}
                    onClick={() => toggleTab(2)}
                  >
                    {t("TRANSFER_DETAILS")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 3 })}
                    onClick={() => toggleTab(3)}
                  >
                    {t("ADDITIONAL_DETAILS")}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Row className="mt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        payee_id: payee_id
                          ? payee_id
                          : selectedPayee
                            ? selectedPayee?.payee_id
                            : "",
                        bankCountry: selectedPayee
                          ? selectedPayee?.bank_country
                          : "",
                        accountNumber: selectedPayee
                          ? selectedPayee?.account_number
                          : "",
                        transferMethod: selectedPayee
                          ? selectedPayee?.payment_methods
                          : "",
                        bankCurrency: selectedPayee
                          ? selectedPayee?.bank_currency
                          : "",
                        payAmount: "",
                        transferOn: "",
                        transferAmount: "",
                        purpose: "",
                        reference: "",
                        description: "",
                        dirDocument: "",
                        accountCurrency: "",
                        swiftOption: "SHARED",
                      }}
                      validationSchema={validationSchemaPayerData}
                      onSubmit={payeeDataNext}
                    >
                      {({ isSubmitting, values, setFieldValue }) => (
                        <Col md={10} xl={8}>
                        <Form>
                          <Col md={12} className="mb-3">
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md={24}>
                                    <h5 className="text-dark-color mb-3">
                                      {t("PAYEE_DETAILS")}
                                    </h5>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payee_id">
                                        {t("PAYEE_NAME")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="payee_id"
                                        id="payee_id"
                                        optionsValues={registeredPayees}
                                        changeDropdown={changePayee}
                                        disabled={!isAccountActive}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="bankCountry">
                                        {t("BANK_COUNTRY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="bankCountry"
                                        id="bankCountry"
                                        disabled={true}
                                        placeholder={t("BANK_COUNTRY")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="accountNumber">
                                        {t("ACCOUNT_NUMBER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="accountNumber"
                                        id="accountNumber"
                                        disabled={true}
                                        placeholder={t("ACCOUNT_NUMBER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="transferMethod">
                                        {t("TRANSFER_METHOD")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="transferMethod"
                                        id="transferMethod"
                                        disabled={true}
                                        placeholder={t("TRANSFER_METHOD")}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="bankCurrency">
                                        {t("BANK_CURRENCY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="bankCurrency"
                                        id="bankCurrency"
                                        disabled={true}
                                        placeholder={t("BANK_CURRENCY")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {selectedPayee?.payment_methods === 'SWIFT' ? (
                                    <Col md={6} xl={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="swiftOption">
                                          {t("SWIFT_OPTION")}
                                          <sup className="text-danger"> *</sup>
                                        </Label>
                                        <FormDropdown
                                          name="swiftOption"
                                          id="swiftOption"
                                          placeholder={t("SWIFT_OPTION")}
                                          optionsValues={swiftOptions}
                                          setFieldValue={setFieldValue}
                                          disabled={!isAccountActive}
                                        />
                                      </FormGroup>
                                    </Col>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                            {quoteData.length > 0 && <Card>
                              <CardBody>
                                <Row>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="quote_id">
                                        {t("FX_LOCK")}
                                        <sup className="text-danger"> </sup>
                                      </Label>
                                      <FormDropdown
                                          name="quote_id"
                                          id="quote_id"
                                          optionsValues={quoteData}
                                          changeDropdown={changeQuote}
                                          disabled={!isAccountActive}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                { tableData.length > 0 && <Row>
                                  <MaterialTableCustomSmall
                                      data={tableData}
                                      columns={columns}
                                      loading={loading}
                                  />
                                </Row>}
                              </CardBody>
                            </Card>}
                            <div className="d-flex justify-content-end mt-3">
                              <Button
                                className="btn-primary"
                                type="submit"
                                disabled={!isAccountActive || loading}
                              >
                                {loading ? (
                                  <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                  ""
                                )}{" "}
                                {t("NEXT")}
                              </Button>
                            </div>
                          </Col>
                        </Form>
                        </Col>
                      )}
                    </Formik>
                  </Row>
                </TabPane>

                <TabPane tabId={2}>
                  <Row className="mt-4">
                  <Col md={10} xl={8}>
                    <Col md={12} className="mb-3">
                      <Card>
                        <CardBody>
                          <h5 className="text-dark-color mb-3">
                            {t("TRANSFER_DETAILS")}
                          </h5>
                          <div ref={payoutFormRef} id="payout-form-container" />
                          {/* {loading ? (
                            <div className="text-center">
                              <Spinner color="primary" />
                            </div>
                          ) : (<div ref={payoutFormRef} id="payee-form-container" />)} */}

                        </CardBody>
                      </Card>

                      <div className="d-flex justify-content-between mt-3">
                        <Button
                          className="btn-secondary"
                          type="button"
                          onClick={() => toggleTab(1)}
                        >
                          {loading ? (
                            <Spinner size="sm">Loading...</Spinner>
                          ) : (
                            ""
                          )}{" "}
                          {t("BACK")}
                        </Button>
                        <Button
                          className="btn-primary"
                          type="button"
                          onClick={() => getTransactionPayload()}
                        >
                          {loading ? (
                            <Spinner size="sm">Loading...</Spinner>
                          ) : (
                            ""
                          )}{" "}
                          {t("NEXT")}
                        </Button>
                      </div>
                    </Col>
                  </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={3}>
                  <Row className="mt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        purpose: "",
                        reference: "",
                        description: "",
                        dirDocument: ""
                      }}
                      validationSchema={validationSchemaAdditionalData}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting, values, setFieldValue }) => (
                        <Col md={10} xl={8}>
                        <Form>
                          <Col md={12} className="mb-3">
                            <Card>
                              <CardBody>
                                <h5 className="text-dark-color mb-3">
                                  {t("ADDITIONAL_DETAILS")}
                                </h5>
                                <Row className="">
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="purpose">
                                        {t("PURPOSE_OF_TRANSFER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="purpose"
                                        id="purpose"
                                        optionsValues={purposeOfTransfer}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("PURPOSE_OF_TRANSFER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="reference">
                                        {t("REFERENCE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="reference"
                                        id="reference"
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("REFERENCE")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="description">
                                        {t("DESCRIPTION")}
                                      </Label>
                                      <FormTextField
                                        name="description"
                                        id="description"
                                        placeholder={t("DESCRIPTION")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="dirDocument">
                                        {t("REFERENCE_DOCUMENT")}
                                      </Label>
                                      <Field
                                        name="dirDocument"
                                        component={FormImageUploader}
                                        label="Choose a file"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            <div className="d-flex justify-content-between mt-3">
                              <Button
                                className="btn-secondary"
                                type="button"
                                onClick={() => toggleTab(2)}
                              >
                                {loading ? (
                                  <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                  ""
                                )}{" "}
                                {t("BACK")}
                              </Button>
                              <Button className="btn-success" type="submit" disabled={!isAccountActive || loading}>
                                {loading ? (
                                  <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                  ""
                                )}{" "}
                                {t("PAY")}
                              </Button>
                            </div>
                          </Col>
                        </Form>
                      </Col>
                      )}
                    </Formik>
                  </Row>

                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
        {isOTPModal ? <PaymentValidateModal
          isOpen={isOTPModal}
          loading={loading}
          setLoading={setLoading}
          handleSave={handleSave}
          handleCancel={handleModalCancel}
          toggleModal={handleModalCancel}
          secondLevelAuth={secondLevelAuth}
        /> : <></>}
      </div>
    </React.Fragment>
  );
};

export default RegisteredPayeeComponent;
