import { post, get,put,del} from "../helpers/api_helper";
import * as url from "./url_helper"


export const saveGlobalAccount = (data,coUserId) => post(url.SAVE_GLOBAL_ACCOUNT+coUserId, data)
export const updateGlobalAccount = (data,coUserId) => post(url.UPDATE_GLOBAL_ACCOUNT+coUserId, data)
export const getGlobalAccounts = (coUserId) => get(url.GET_GLOBAL_ACCOUNTS+coUserId)

export const deleteGlobalAccounts = (airwallexGlobalAccountId) => del(url.DELETE_GLOBAL_ACCOUNTS+airwallexGlobalAccountId)
export const downloadGlobalAccountStatement = (data) => post(url.DOWNLOAD_GLOBAL_ACCOUNT_STATEMENT, data)
